
.standard-page {
    &__content {
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        @include breakpoint(dt) {
            padding-bottom: 40px; }
        @include breakpoint(md) {
            padding-left: 30px; }
        @include breakpoint(sm) {
            padding-left: 0;
            padding-top: 0; } }
    h3 {
        font-weight: 500;
        color: $dark-grey;
        margin: 0;
        margin-bottom: 1rem; }
    strong {
        color: $dark-grey; }
    .content p {
        font-weight: 300;
        @include breakpoint(dt) {
            font-size: vw(20);
            line-height: vw(28); } }
    &__subsections {
        @extend .z-depth-1;
        margin: 80px 0; }

    &__subsection {
        border-bottom: 1px solid rgba(black,0.2);
        position: relative;
        &__title {
            padding: 0 35px;
            min-height: 70px;
            font-weight: 500;
            font-size: vw(21);
            margin: 0;
            line-height: 30px;
            color: $dark-grey;
            cursor: pointer;
            @extend .flex-vertical;
            @include breakpoint(md) {
                font-size: 18px;
                line-height: 22px;
                padding-right: 45px; }
            @include breakpoint(sm) {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 20px;
                font-size: 16px; }
            svg {
                position: absolute;
                right: 35px;
                height: 14px;
                top: 23px;
                transform: rotate(-180deg);
                @include transition;
                .is-open & {
                    transform: rotate(-90deg); }
                @include breakpoint(md) {
                    right: 20px; }
                path {
 } } }                    // fill: $dark-grey
        &__content {
            padding: 0 35px;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            @include transition;
            @include breakpoint(sm) {
                padding: 0 20px; }
            p {
                color: #656565;
                @include breakpoint(sm) {
                    font-size: 14px; } }
            .is-open & {
                max-height: 5000px;
                opacity: 1; } } }
    &__price {
        margin-bottom: 80px;
        // border: 1px solid rgba(black,0.2)
        @extend .z-depth-1;
        span {
            line-height: 70px;
            height: 70px;
            font-weight: 500;
            color: $dark-grey;
            @include breakpoint(sm) {
                font-size: 12px; } }
        &__row {
            height: 70px;
            border-bottom: 1px solid rgba(black,0.2);
            display: flex;
            &:last-child {
                border: none; } }
        &__tag {
            width: 40%;
            padding-left: 35px;
            @include breakpoint(sm) {
                padding-left: 10px; } }
        &__item {
            width: 20%;
            @extend .flex-center;
            border-left: 1px solid rgba(black,0.2); } } }
