// @include font-face('OpenSans','../fonts/OpenSans/OpenSans-Semibold','eot ttf')

h1, h2, h3, h5 {
	font-family: Raleway;
	font-weight: 100;
	letter-spacing: 0; }
h1 {
	font-size: vw(42);
	line-height: vw(44);
	@include breakpoint(md) {
		font-size: 36px;
		line-height: 40px; }
	@include breakpoint(sm) {
		font-size: 26px;
		line-height: 30px; } }
h2 {
	font-size: vw(34);
	font-weight: 250;
	line-height: vw(44);
	color: $link-grey;
	@include breakpoint(md) {
		font-size: 32px;
		line-height: 36px; }
	@include breakpoint(sm) {
		font-size: 26px;
		line-height: 30px; } }
h3 {
	font-size: vw(30);
	line-height: vw(44);
	@include breakpoint(md) {
		font-size: 26px;
		line-height: 36px; }
	@include breakpoint(sm) {
		font-size: 26px;
		line-height: 30px; } }
h4 {
	font-size: vw(24);
	line-height: vw(30);
	@include breakpoint(md) {
		font-size: 16px;
		line-height: 26px; }
	@include breakpoint(sm) {
		font-size: 16px;
		line-height: 20px; } }
h5 {
	font-size: vw(20);
	color: $dark-grey;
	font-weight: 700;
	@include breakpoint(md) {
		font-size: 20px;
		line-height: 26px; }
	@include breakpoint(sm) {
		font-size: 20px;
		line-height: 26px; } }

p {
	font-weight: 400;
	font-size: 15px;
	line-height: 26px;
 }	//max-width: 1200px
a {
	font-weight: 500;
	color: inherit;
	text-decoration: none;
	// color: initial
	// &:visited, &:focus
 }	// 	color: initial
i {
	font-style: italic;
	font-weight: 400; }

strong {
	font-weight: 700; }


nav {
	ul {
		padding: 0; }
	li {
		list-style-type: none !important; }

	a {
		text-decoration: none;
		// font-family: OpenSans
		// font-weight: 600
		font-weight: {}
		font-size: 1rem;
		// color: $link-grey !important
		color: $link-grey; } }
ul {
	padding-left: 1rem;
	list-style-type: initial;
	li {
		@extend p;
		list-style-type: initial; } }

