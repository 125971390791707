$number-of-columns: 12;

$breakpoints: sm 320px 100%, md 768px 100%, dt 1025px 100%, lg 1440px 100%, xl 1920px 100%;

$xs: 320;
$sm: 767;
$md: 1024;
$dt: 1025;
$lg: 1440;
$xl: 1920;

$mediaquerries: (xs: "max-width: #{$xs}px", sm: "max-width: #{$sm}px", sm-l: "max-width: #{$sm}px) and (orientation: landscape", md: "max-width: #{$md}px", mdonly: "min-width: 768px) and (max-width: #{$md}px", dt: "min-width: #{$dt}px", lg: "min-width: #{$lg}px", xl: "min-width: #{$xl}px");

$page-width: 1160px;
$gutter: 1.5rem;

$base-font-size: 16;

$animationDistance: 50px;

$transition: 0.25s;
$transition2: 0.5s;
$easing: cubic-bezier(.77, 0, .175, 1);

$link-grey: #656565;
$green: #005457;
$orange: #EE7262;
$light-grey: #F3F3F3;

$dark-grey: #70706F;


$darkblue: #07185d;
$darkgrey: #414241;
$darkgreyblue: #637381;
$lightblue: #07185d;

$primary: $darkgrey;
$secondary: $lightblue;
$third: $light-grey;
