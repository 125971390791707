.sidebar-nav {
    // animation: fadeupbehandling $transition 1s forwards
    // opacity: 0
    // top: -70px

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.14);
    transform: translateY(-70px);
    position: relative;
    overflow: hidden;
    @include breakpoint(sm) {
 }        // display: none
    &:after, &:before {
        content: '';
        position: absolute;
        top: 0px;
        display: block;
        z-index: 10;
        height: 200px;
        width: 50px;
        opacity: 0.67;
        background-image: linear-gradient(80deg, #FFFFFF 46%, #D8D8D8 100%); }

    &:before {
        left: -50px; }
    &:after {
        right: -50px;
        transform: rotateY(180deg); }
    &__ul {
        padding: 0;
        margin: 0;
        &>li {
            border-bottom: 1px solid rgba(black,0.3); } }
    &__title, &__li {
        padding-left: 35px;
        padding-left: 20px;
        @include breakpoint(md) {
            padding-left: 20px; } }
    &__title {
        height: 70px;
        line-height: 70px;
        background: $primary;
        color: white;
        margin: 0;
        transform: translateY(-70px);
        @include breakpoint(sm) {
 } }            // display: none
    &__li {
        min-height: 50px;
        line-height: 50px;
        @extend .flex-vertical;
        a, span, p, h2 {
            font-weight: 500;
            color: $dark-grey;

            // line-height: 30px
            font-size: 16px;
            @include breakpoint(md);
            @include breakpoint(dt) {
                font-size: vw(16); } }
        a {
            padding: 5px 0;
            @include transition;
            //height: auto
            padding-right: 20px;
            width: 100%;
            height: 100%;
            &:hover {
                color: $orange;
                //background-color: $primary
 } }                //font-weight: bold
        h2 {
            padding-right: 30px; }

        &.is-open {
            height: auto;

            &>.arrow {
                svg {
                    transform: rotate(90deg); } } }

        &.current-menu-item {
            background-color: $third;
            //pointer-events: none
            a {
                pointer-events: none;
                color: $orange;
                //background-color: $primary
 } } }                //font-weight: bold

    &__parent {
        width: 100%;
        margin: 0;
        min-height: 60px;
        line-height: 20px;
        cursor: pointer;
        @extend .flex-vertical !optional;
 }        //padding-right: 30px

    &__grand-child {
        // padding-left: 20px
        min-height: 60px;
        opacity: 0;
        transform: translateY(-10px);
        border: none !important;
        @include transition;
        //line-height: 20px
        @include breakpoint(md) {
            padding-left: 10px;
 }            //padding-right: 10px
        // &:hover
        //     &>a
        //         color: $lightblue
        .is-open>*>& {
            opacity: 1;
            transform: none; }
        &:hover {
            background-color: $third; }

        &.sidebar-nav--has-children {
            &:hover {
                background-color: white; } }

        &.current-menu-item {
            background-color: $third;
            //pointer-events: none
            a {
                pointer-events: none;
                color: $orange;
 } }                //font-weight: bold

        // @for $i from 1 through 15
        //     &:nth-child(#{$i})
 }        //         transition-delay: calc( #{$i} * 0.05s )


    &__sub-menu {
        opacity: 0;
        max-height: 0;
        width: 100%;
        pointer-events: none;
        @include transition;
        .is-open>& {
            max-height: 1500px;
            opacity: 1;
            pointer-events: auto; } }

    &--has-children {
        border-bottom: 1px solid rgba(black,0.3);
        position: relative;
        min-height: 60px;
        line-height: 60px;
        svg {
            position: absolute;
            right: 15px;
            height: 14px;
            top: calc(50% - 7px);
            top: 23px;
            // transform: rotate(-180deg)
            @include transition;
            color: $dark-grey;
            stroke-width: 2px; } }


    &--no-children {
        &:hover {
            background-color: $third; }
 } }        // pointer-events: none
