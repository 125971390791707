.about-nav {
    // animation: fadeupbehandling $transition 1s forwards
    // opacity: 0
    // top: -70px
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.14);
    transform: translateY(-70px);
    position: relative;
    @include breakpoint(sm) {
        display: none; }
    &:after, &:before {
        content: '';
        position: absolute;
        top: 0px;
        display: block;
        z-index: 10;
        height: 200px;
        width: 50px;
        opacity: 0.67;
        background-image: linear-gradient(80deg, #FFFFFF 46%, #D8D8D8 100%); }
    &:before {
        left: -50px; }
    &:after {
        right: -50px;
        transform: rotateY(180deg); }
    &__ul {
        padding: 0;
        margin: 0; }
    &__title, &__li {
        padding-left: 35px;
        @include breakpoint(md) {
            padding-left: 20px; }
        @include breakpoint(sm) {
            display: none; } }
    &__title {
        height: 70px;
        line-height: 70px;
        background: $orange;
        color: white;
        margin: 0;
        transform: translateY(-70px); }
    &__li {
        min-height: 60px;
        line-height: 60px;
        @extend .flex-vertical;
        border-bottom: 1px solid rgba(black,0.3);
        &:last-child {
            border: none; }
        a {
            font-weight: 500;
            // font-size: vw(16)
            color: $dark-grey;
            @include transitionSlow;
            @include breakpoint(dt) {
                font-size: vw(16); } }

        &.is-open {
            height: auto;
            svg {
                transform: rotate(-90deg); } }
        &.current-menu-item {
            pointer-events: none;
            a {
                pointer-events: none;
                color: $orange; } } } }

.about {
    &__content {
        padding-left: 50px;
        padding-top: 50px;
        @include breakpoint(md) {
            padding-left: 30px; }
        @include breakpoint(sm) {
            padding: 40px 0; } }
    h3 {
        font-weight: 500;
        color: $dark-grey;
        margin: 0;
        margin-bottom: 1rem; }
    strong {
        color: $dark-grey; }
    .content p {
        font-weight: 300;
        @include breakpoint(dt) {
            font-size: vw(20);
            line-height: vw(28); } } }
