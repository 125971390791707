/**
 * (optional) define here the style definitions which should be applied on the slider container
 * e.g. width including further controls like arrows etc.
 */
.slider {}
.frame {
	/**
	 * (optional) wrapper width, specifies width of the slider frame.
	 */
	// width: 880px
	position: relative;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	white-space: nowrap;
	// margin-bottom: 40px
 }	// padding-top: 40px

.slides {
	display: inline-block; }


.frame li {
	position: relative;
	display: inline-block;
	white-space: initial; }

.prev, .next {
	position: absolute;
	//top: 45%
	margin-top: -25px;
	top: 47%;
	display: block;
	cursor: pointer;
	@include breakpoint(sm) {
		top: 50%; } }

.next {
	right: 20px; }


.prev {
	left: 0; }





