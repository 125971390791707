.contact-banner {
	background: $primary;
	padding-top: 40px;
	padding-bottom: 40px;
	//margin-top: 80px
	//display: flex
	color: white;
	position: relative;
	@include breakpoint(md) {
		display: block;
		height: 350px; }
	@include breakpoint(sm) {
		margin: 0;
		height: 400px; }
	@include breakpoint(xs) {
		height: 440px; }
	&__left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		//padding-top: 40px
		padding-bottom: 20px;
		//margin-bottom: 40px
		margin-top: 30px;
		@include breakpoint(md) {
			padding-bottom: 70px; }
		@include breakpoint(sm) {
			padding-bottom: 150px;
 }			//padding-top: 70px
		@include breakpoint(xs) {
 } }			//padding-top: 70px

	&__tekst {
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 22px;
		// font-size: 22px
		// font-weight: 500
		// margin-top: 0
		// +breakpoint(dt)
		// 	font-size: 20px
		// +breakpoint(md)
		// 	font-size: 18px
		// +breakpoint(sm)
		// 	font-size: 16px
 }		// 	font-weight: 700
	&__link {
		background: white;
		//background: $secondary
		// box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
		width: 180px;
		border-radius: 4px;
		text-decoration: none;
		text-align: center;
		padding: 10px 56px;
		@include breakpoint(sm) {
            padding: 8px 50px; }
		// +breakpoint(md)
		// 	width: 120px
		// 	padding: 8px 10px
		color: $primary;
		font-family: Raleway;
		font-size: 16px;
		@extend .z-depth-2;
		font-weight: 700;
		@include transition;
		&:hover {
			@extend .z-depth-4; }
		opacity: 0;
		animation: fadeup $transition2 $transition2 forwards; }

	&__middle {}

	&__img {
		position: absolute;
		right: 180px;
		bottom: 10px;
		max-height: 300px;


		margin: 0;
		max-width: 100%;
		height: auto;
		min-width: 200px;
		@include breakpoint(md) {
			width: 250px;
			bottom: 70px; }
		@include breakpoint(sm) {
			bottom: -60px;
			width: 150px;
			left: 10px; }
		@include breakpoint(xs) {
			display: none; }

		&:hover {
			filter: grayscale(60%); } }

	&__right {
		position: absolute;
		bottom: 30px;
		display: flex;
		flex-direction: column;
		align-self: flex-end;
		padding-bottom: 20px;
		right: 20px;
		@include breakpoint(md) {
			bottom: 80px; }
		@include breakpoint(sm) {
			left: 180px;
			bottom: -50px; } }

	&__name {
		font-size: 15px; }
	&__title {
		display: block;
		font-size: 13px; }
	&__signature {
		margin-top: 10px;
		width: 180px; } }

.sp-mask {}

.slider-pro {}

.sp-slides-container {}

.sp-layer {
	height: 300px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	color: white;
	@include breakpoint(md) {
		height: 350px; }
	@include breakpoint(sm) {
		height: 450px; }
	@include breakpoint(xs) {
		height: 550px; } }

.contact-slider {
	background-color: $primary;
	background-image: url(img/light-grey.png);
	background-repeat: repeat-x; }


.sp-arrows {
	top: 54% !important; }
