.front {
    &__hero {
        background: transparent;
        &__content {
            height: 100%;
            max-height: 1080px;
            opacity: 0;
            animation: fadeup $transition2 forwards;
            @extend .flex-vertical;
            position: absolute;
            bottom: -10%; }
        &__wrap {
            position: relative;
            text-align: left;
            color: white; }
        &__text {
            color: white;
            opacity: 0;
            max-width: 800px;
            animation: fadeup $transition2 $transition2 forwards;
            h2 {
                font-weight: 360; }
            @include breakpoint(md) {
                //height: 80vh
                h2 {
                    font-size: 26px !important; } }
            @include breakpoint(sm) {
                //height: 60vh
                h2 {
                    font-size: 20px !important; } }
            @include breakpoint(xs) {
                h2 {
                    font-size: 18px !important; } } }
        &__link {
            background: $orange;
            // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
            border-radius: 4px;
            text-decoration: none;
            padding: 10px 20px;
            @include breakpoint(sm) {
                padding: 8px 12px; }
            color: white;
            font-family: Raleway;
            font-weight: 500;
            font-size: 16px;
            @extend .z-depth-2;
            @include transition;
            &:hover {
                @extend .z-depth-4; }
            opacity: 0;
            animation: fadeup $transition2 $transition2 forwards; }
        &__img {
            max-height: 1080px;
            //height: 60vw
            height: 100vw;
            min-height: 450px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;
            opacity: 0;
            animation: fadeup $transition2 $transition2 forwards;
            z-index: -1 !important;
            // background-color: transparent !important
            // background-size: contain !important
            // background-position: right bottom !important
            // +breakpoint(md)
            //  height: 70vh
            // +breakpoint(sm)
 } } }            //  height: 50vh

.testimonials-front {
    position: relative;

    padding-top: 40px;
    padding-bottom: 0;
    background: $light-grey;
    &:before {
        background: $light-grey; }
    &:after {
        content: '';
        position: absolute;
        top: 70%;
        left: 0;
        height: 50%;
        width: 100%;
        background: $light-grey;
        z-index: -1; }
    &__inner {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24);
        animation: fadeup $transition 0.5s $easing forwards;
        // transform: translateY(-100px)
        opacity: 0;
        margin-top: -90px;
        transform: translateY(100px);
        background: white;
        position: relative;
        @include breakpoint(lg) {
            padding: 100px 120px;
            margin-bottom: 80px; }
        @include breakpoint(dt) {
            padding: 80px 40px;
            //margin-top: 40px
            margin-bottom: 80px; }
        @include breakpoint(md) {
            padding: 80px 40px;
            //margin-top: 40px
            margin-bottom: 80px; }
        @include breakpoint(sm) {
            padding: 50px 40px;
            padding-bottom: 60px;
            //margin-top: 40px
            margin-bottom: 80px;

 }            //padding: 40px 20px
        &:after, &:before {
            content: '';
            position: absolute;
            top: 100px;
            display: block;
            z-index: 10;
            height: 250px;
            width: 70px;
            opacity: 0.67;
            //background-image: linear-gradient(80deg, #FFFFFF 46%, #D8D8D8 100%)
            @include breakpoint(md) {
                top: 80px;
                width: 40px; }
            @include breakpoint(sm) {
 } }                //display: none
        &:before {
            left: -70px;
            @include breakpoint(md) {
                left: -40px; } }
        &:after {
            right: -70px;
            transform: rotateY(180deg);
            @include breakpoint(md) {
                right: -40px; } } }
    &__title {
        margin-top: 0;
        padding-bottom: 20px;
        color: $orange;
        text-align: center;
        margin: 0 auto; }
    &__description p {
        font-weight: 600;
        max-width: 1000px;
        padding: 0 50px;
        color: $link-grey;
        text-align: center;
        margin: 0 auto;
        // max-width: 1000px
        margin-bottom: 40px;
        @include breakpoint(sm) {
            padding: 0;
 } }            //margin-bottom: 20px
    &__slider {
        position: relative;
        padding: 0 40px;
        @include breakpoint(sm) {
            padding: 0; } }
    .prev, .next {
        border: 1px solid $orange;
        color: $orange;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        @include breakpoint(sm) {
            padding-top: 20px;
            color: $orange;
            height: 35px;
            width: 35px;
            border: none;
            box-shadow: none;
            &:hover {
               box-shadow: none; } }
        // margin: 0
        @extend .flex-center;
        @extend .z-depth-2;
        &:hover {
            @extend .z-depth-3; }

        svg {
            margin-right: 3px; } }
    .prev {
        left: -6px;
        @include breakpoint(md) {
            left: -6px; }
        @include breakpoint(sm) {
            left: -2px; } }
    .next {
        right: -6px;
        transform: rotateY(180deg);
        @include breakpoint(md) {
            right: -6px; }
        @include breakpoint(sm) {
            right: -2px; } }
    .js_slides {
        width: 100%;
        padding: 0;
        @include breakpoint(md) {
 } }            // width: 80%
    .js_slide {
 }        // margin: 20px
    &__li {
        width: calc(100% / 1);
        min-height: 300px;
        padding: 40px 40px;
        @include breakpoint(xl) {
            width: calc(100% / 2); }
        @include breakpoint(sm) {
            padding: 40px 0px;
            padding-top: 60px;
            margin: 0px 20px; }
        //padding-bottom: 60px
        min-height: 0; }
    &__li__inner {
        display: flex;
        flex-direction: row;
        background: $light-grey;
        min-height: 250px;
        //height: 100%
        padding: 40px 20px;
        padding-bottom: 40px;
        @include breakpoint(md) {
            padding: 40px 20px;
            padding-bottom: 40px; }
        @include breakpoint(sm) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 70px 30px;
            padding-bottom: 40px; } }

    &__img {}

    &__content {
        text-align: left;
        padding-left: 30px;
        @include breakpoint(md) {
            padding-left: 15px; }

        @include breakpoint(sm) {
            padding-left: 0; } }
    &__texttitle {
        font-weight: 500;
        margin: 0;
 }        //font-size: 15px
    &__text {
        margin-top: 6px; }

    &__link {
        //@extend .flex-center
        background: $orange;
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
        border-radius: 4px;
        text-decoration: none;
        padding: 10px 20px;
        @include breakpoint(sm) {
            padding: 8px 20px; }
        color: white;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 500;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards; } }

.card-front {
    &__inner {
        position: relative;
        background: $light-grey;
        height: 100%;
        &__foer-efter {
            padding: 20px;
            position: relative;
            background: $light-grey;
            height: 100%; } }
    figure {
        flex-shrink: 0;
        align-self: center;
        margin-right: 10px;
        // position: absolute
        // top: calc(50% - 0px)
        // left: calc( 20% - 60px)
        border: 6px solid white;
        border-radius: 50%;
        background: $light-grey;
        color: $primary;
        height: 180px;
        width: 180px;
        // @extend .flex-center
        // margin: 0 auto
        overflow: hidden;
        @include breakpoint(sm) {
            position: absolute;
            top: 0px;
            left: calc( 50% - 59px);
            height: 120px;
            width: 120px;
            border: 4px solid white;
            margin: 0 auto;


            // margin-left: 10px
            // height: 140px
            // width: 140px
 } }            // border: 4px solid white

    strong {
        color: #656565z;
        font-style: italic;
        font-weight: 600; } }

.social {
    h2 {
        margin: 70px 0; }
    h3 {
        font-weight: 500;
        color: $dark-grey; }
    &__card {
        height: 100%;
        position: relative;
        background: $light-grey;
        height: 100%;
        padding: 50px 40px;
        border-bottom: 80px solid white;
        @include breakpoint(sm) {
            border-bottom: 60px solid white; } }
    p {
        color: $link-grey; }
    a {
        text-decoration: none;
        display: block;
        &:hover {
            figure {
                transform: scale(1.1); } } }
    figure {
        @include transition;
        transform-origin: 50%;
        backface-visibility: hidden;
        top: -40px;
        svg {
            position: absolute;
            height: 30px;
            width: 30px;
            top: calc(50% - 15px);
            left: calc(50% - 15px); }
        position: absolute;
        top: -40px;
        left: calc( 50% - 50px);
        border: 8px solid white;
        border-radius: 50%;
        background: $light-grey;
        color: $primary;
        height: 100px;
        width: 100px;
        @extend .flex-center;
        margin: 0 auto;
        overflow: hidden; } }

.om-klinikken {
    padding-top: 80px;
    padding-bottom: 80px;
    background: $light-grey;
    @include breakpoint(sm) {
        padding-top: 40px; }
    h2 {
        margin-top: 0;
        margin-bottom: 10px;
        color: $orange; }
    p {
        margin-bottom: 36px;
        margin-right: 40px;
        max-width: 600px; }

    &__link {
        background: $orange;
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
        border-radius: 4px;
        text-decoration: none;
        width: 180px;
        padding: 10px 50px;
        @include breakpoint(sm) {
            padding: 8px 50px; }
        color: white;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 500;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards; }
    &__image {
        height: 100%;
        max-height: 400px;
        max-width: 560px;
        width: 100%; } }

.right-box {
    //float: right
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    //padding-bottom: 80px
    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background: $orange;
        color: white;
        padding: 30px;
        //padding-top: 10px
        padding-bottom: 40px;
        h2 {
            color: white !important;
 }            //margin-bottom: 20px !important
        p {
            margin-left: 0;
            margin-right: 0 !important; } }
    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        //margin-left: 20%
        padding: 30px;
        padding-bottom: 80px;
        text-align: center;
        p {
            margin-right: 0 !important; }
        @include breakpoint(sm) {
            margin-left: 0;
            padding-bottom: 70px; } } }

.left-box {
    //padding-bottom: 80px
    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background: $primary;
        color: white;
        padding: 30px;
        //padding-top: 10px
        padding-bottom: 40px;
        h2 {
            color: white !important;
 }            //margin-bottom: 20px !important
        p {
            margin-left: 0;
            margin-right: 0 !important; } }
    &__juxtapose {
        //margin-bottom: 40px
        height: 100%; }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        //margin-left: 20%
        text-align: center;
        padding: 30px;
        p {
            margin-right: 0 !important; }
        @include breakpoint(sm) {
            margin-left: 0;
            padding-bottom: 70px; } } }

.foer-efter {
    align-items: center;
    //border-bottom: 1px solid $light-grey
    padding-top: 80px;
    padding-bottom: 80px;
    //padding-bottom: 80px
    background: white;
    @include breakpoint(sm) {
        padding-top: 50px; }
    h2 {
        margin-top: 0;
        margin-bottom: 10px;
        color: $orange; }
    p {
        margin-bottom: 26px;
        //margin-right: 40px
        max-width: 600px;
 }        //text-align: center
    h3 {
        margin-top: 0;
        margin-bottom: 30px;
        font-weight: 500;
        color: white; }
    &__inner {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24);
        animation: fadeup $transition 0.5s $easing forwards;
        // transform: translateY(-100px)
        opacity: 0;
        transform: translateY(100px);
        background: white;
        position: relative;
        padding: 40px;
        margin-bottom: 50px;
        &.popup {
            padding: 20px; } }


    &__link {
        background: $orange;
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
        border-radius: 4px;
        text-decoration: none;
        width: 180px;
        padding: 10px 50px;
        @include breakpoint(sm) {
            padding: 8px 50px; }
        color: white;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 500;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards; }
    &__link2 {
        background: white;
        //background: $secondary
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
        border-radius: 4px;
        text-decoration: none;
        padding: 10px 50px;
        @include breakpoint(sm) {
            padding: 8px 50px; }
        color: $primary;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 700;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards; }
    &__link3 {
        background: white;
        //background: $secondary
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
        border-radius: 4px;
        text-decoration: none;
        padding: 10px 50px;
        @include breakpoint(sm) {
            padding: 8px 50px; }
        color: $orange;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 700;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards; }
    &__image {
        display: flex;
        justify-content: center; } }

// juxtapose styling //

.knightlab-logo {
    visibility: hidden;
    background-color: transparent; }

.juxtapose-name {
    visibility: hidden; }

.jx-knightlab {
    display: none !important; }

.juxtapose {
    display: block;
    height: 100%;
    width: 100% !important;
    margin-bottom: 40px;
    max-height: 500px;
    max-width: 500px;
    &.forside {
        //float: right
        margin-bottom: 80px;
        max-height: 400px;
        max-width: 560px;
        @include breakpoint(sm) {
            //float: left

            margin-bottom: 40px; } }
    &.popup {
        height: 297px !important;
        width: 297px !important;
        display: flex;
        flex-direction: row;
        @include breakpoint(sm) {
            height: 267px !important;
            width: 267px !important; }


        //height: 100%
        //width: 100%
 }        //max-height: 500px
    &__title {
        h5 {
            margin-top: 0;
            color: $primary;
            margin-bottom: 10px;
            font-weight: 600; } }

    &__kirurg {
        padding-bottom: 30px;
        h6 {
            margin: 0;
            color: $primary;
            font-style: italic;
            font-size: 14px; } }


    &__ekstra {
        max-width: 500px; }

    // &.juxtapose-0
    //  margin-bottom: 80px
 }    //  max-width: 500px


.jx-image {
    img {
        height: auto;
        width: auto;
        display: block; } }
.jx.slider {}


