.contact {
    .content {
        padding-top: 10px;
        padding-bottom: 80px;
        h5 {
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin-top: 50px;
            margin-bottom: 20px;
            @include breakpoint(md) {
                margin-top: 40px; } }
        p, a {
            font-weight: 300;
            margin-top: 0;
            line-height: vw(24);
            @include breakpoint(dt) {
                font-size: vw(20); } } }

    p.u-light-text {
        font-size: 16px;
        // margin-top: 20px
        // max-width: 60%
        @include breakpoint(dt) {
            font-size: vw(16);
            line-height: vw(25); } } }

.person {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    @include breakpoint(sm) {
        margin-bottom: 40px; }
    &__img {
        //width: 400px
        //height: 400px
        // width: 40vw
        // height: 40vw
        //max-height: 500px
        //max-width: 500px
        background: center / cover no-repeat;
        margin: 0;
        max-width: 100%;
        height: auto !important;
        margin-bottom: 20px;
        border-style: hidden;
        // +breakpoint(sm)
        //     width: 100%
 }        //     height: 80vw

    &__position, &__name, strong, p {
        @include breakpoint(dt) {
            font-size: vw(16);
            line-height: vw(25); } }
    &__name {
        // margin-bottom: 20px
        padding: 0;
        line-height: 20px;
        display: block;
        color: $dark-grey; }
    &__position {
        font-weight: 200 !important;
        // font-size: 16px
 }        // font-weight: 200
    &__links {}

    &__website {
        font-weight: 500;
        color: $orange;
        font-size: 15px;
        line-height: 25px;
        @include breakpoint(md) {
            font-size: 14px; } } }

#googlemaps {
    height: 100%;
    width: 100%; }

