.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 86px;
    background: $primary;
    padding-top: 20px;
    padding-bottom: 20px;
    // align-self: flex-end
    @include breakpoint(sm) {
        height: 300px; }
    @include transition;
    .is-transitioning & {
        opacity: 0; }
    .row>div {
        @include breakpoint(sm) {
            margin-bottom: 20px; } }
    &__logo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        //margin-bottom: 30px
        @include breakpoint(sm) {
            flex-direction: initial;
            margin-top: 20px; }

        svg {
            fill: white;
            fill: white;
            height: 34px;
            width: auto;
            @include breakpoint(sm) {
                height: 40px; } } }
    p, strong {
        margin: 0;
        color: white;
        //padding: 0
        font-size: 12px !important;
        line-height: 16px !important;
        //width: 80%
        @include breakpoint(sm) {
 } }            //text-align: center
    strong {
        @include breakpoint(sm) {
            text-align: center; } }

    h3, a {
        //@extend .flex-center
        color: white;
        color: white;
        font-weight: 500;
        //margin-top: 20px
        text-decoration: none; }
    &__address, p {
        display: flex;
        justify-content: center;
        flex-direction: column;
        //height: 100%
        p {
            font-size: 10px;
            line-height: 10px; }
        @include breakpoint(sm) {
            text-align: center; } }
    &__phone {
        display: flex;
        justify-content: center;
        flex-direction: column;
        //height: 100%
        @include breakpoint(sm) {
            text-align: center; }
        h3 {
            //margin-top: 10px
            @include breakpoint(sm) {
                margin-top: 2px;
                font-size: 16px; } } }
    &__social {
        display: flex;
        margin-top: 3px;
        @include breakpoint(sm) {
            justify-content: center; }
        &__link {
            //margin-top: 8px
            margin-right: 10px;
            @include breakpoint(sm) {
                margin-right: 5px;
                margin-left: 5px; }
            //margin: 4px
            background: white;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            position: relative;
            svg {
                height: 20px;
                width: 20px;
                position: absolute;
                top: calc(50% - 10px);
                left: calc(50% - 10px);
                path {
                    fill: $primary; } } } }
    &__follow-us {
        @include breakpoint(lg) {
            margin-top: 0 !important; }
        @include breakpoint(dt) {
            margin-top: 18px; }
        @include breakpoint(md) {
            margin-top: 18px; }
        @include breakpoint(sm) {
            margin-top: 20px;
            text-align: center; } } }
