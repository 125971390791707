.behandlinger-nav {
    // animation: fadeupbehandling $transition 1s forwards
    // opacity: 0
    // top: -70px
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.14);
    transform: translateY(-70px);
    position: relative;
    @include breakpoint(sm) {
 }        // display: none
    &:after, &:before {
        content: '';
        position: absolute;
        top: 0px;
        display: block;
        z-index: 10;
        height: 200px;
        width: 50px;
        opacity: 0.67;
        background-image: linear-gradient(80deg, #FFFFFF 46%, #D8D8D8 100%); }

    &:before {
        left: -50px; }
    &:after {
        right: -50px;
        transform: rotateY(180deg); }
    &__ul {
        padding: 0;
        margin: 0;
        &>li {
            border-bottom: 1px solid rgba(black,0.3); } }
    &__title, &__li {
        padding-left: 35px;
        padding-left: 20px;
        @include breakpoint(md) {
            padding-left: 20px; } }
    &__title {
        height: 70px;
        line-height: 70px;
        background: $darkblue;
        color: white;
        margin: 0;
        transform: translateY(-70px);
        @include breakpoint(sm) {
 } }            // display: none
    &__li {
        min-height: 60px;
        line-height: 60px;
        @extend .flex-vertical;
        a, span, p {
            font-weight: 500;
            color: $dark-grey;
            // line-height: 30px
            @include breakpoint(dt) {
                font-size: vw(16); } }
        a {
            padding: 5px 0;
            @include transition;
            &:hover {
                color: $lightblue; } }
        &.is-open {
            height: auto;
            &>.arrow {
                svg {
                    transform: rotate(-90deg); } } } }
    &__parent {
        width: 100%;
        margin: 0;
        height: 60px;
        line-height: 60px;
        cursor: pointer; }
    &__grand-child {
        // padding-left: 20px
        min-height: 60px;
        opacity: 0;
        transform: translateY(-10px);
        border: none !important;
        @include transition;
        line-height: 20px;
        @include breakpoint(md) {
            padding-left: 10px;
            padding-right: 10px; }
        // &:hover
        //     &>a
        //         color: $lightblue
        .is-open>*>& {
            opacity: 1;
            transform: none; }
        &.current-menu-item {
            pointer-events: none;
            a {
                pointer-events: none;
                color: $lightblue; } }
        @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
                transition-delay: calc( #{$i} * 0.05s ); } } }
    &__sub-menu {
        opacity: 0;
        max-height: 0;
        width: 100%;
        pointer-events: none;
        @include transition;
        .is-open>& {
            max-height: 1500px;
            opacity: 1;
            pointer-events: auto; } }
    &--has-children {
        border-bottom: 1px solid rgba(black,0.3);
        position: relative;
        min-height: 60px;
        line-height: 60px;
        svg {
            position: absolute;
            right: 15px;
            height: 14px;
            top: calc(50% - 7px);
            top: 23px;
            transform: rotate(-180deg);
            @include transition;
            path {
                fill: $dark-grey; } } }


    &--no-children {
 } }        // pointer-events: none

.behandling {
    &__content {
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        @include breakpoint(dt) {
            padding-bottom: 40px; }
        @include breakpoint(md) {
            padding-left: 30px; }
        @include breakpoint(sm) {
            padding-left: 0;
            padding-top: 0; } }
    h3 {
        font-weight: 500;
        color: $dark-grey;
        margin: 0;
        margin-bottom: 1rem; }
    strong {
        color: $dark-grey; }
    .content p {
        font-weight: 300;
        margin-bottom: 40px;
        @include breakpoint(dt) {
            font-size: vw(20);
            line-height: vw(28); } }
    &__subsections {
        @extend .z-depth-1;
        margin: 80px 0; }

    &__subsection {
        border-bottom: 1px solid rgba(black,0.2);
        position: relative;
        &__title {
            padding: 0 35px;
            min-height: 70px;
            font-weight: 500;
            font-size: vw(21);
            margin: 0;
            line-height: 30px;
            color: $dark-grey;
            cursor: pointer;
            @extend .flex-vertical;
            @include breakpoint(md) {
                font-size: 18px;
                line-height: 22px;
                padding-right: 45px; }
            @include breakpoint(sm) {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 20px;
                font-size: 16px; }
            svg {
                position: absolute;
                right: 35px;
                height: 14px;
                top: 23px;
                transform: rotate(-180deg);
                @include transition;
                .is-open & {
                    transform: rotate(-90deg); }
                @include breakpoint(md) {
                    right: 20px; }
                path {
 } } }                    // fill: $dark-grey
        &__content {
            padding: 0 35px;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            @include transition;
            @include breakpoint(sm) {
                padding: 0 20px; }
            p {
                color: #656565;
                @include breakpoint(sm) {
                    font-size: 14px; } }
            .is-open & {
                max-height: 5000px;
                opacity: 1; } } }
    &__price {
        margin-bottom: 80px;
        // border: 1px solid rgba(black,0.2)
        @extend .z-depth-1;
        span {
            line-height: 70px;
            height: 70px;
            font-weight: 500;
            color: $dark-grey;
            @include breakpoint(sm) {
                font-size: 12px; } }
        &__row {
            height: 70px;
            border-bottom: 1px solid rgba(black,0.2);
            display: flex;
            &:last-child {
                border: none; } }
        &__tag {
            width: 40%;
            padding-left: 35px;
            @include breakpoint(sm) {
                padding-left: 10px; } }
        &__item {
            width: 20%;
            @extend .flex-center;
            border-left: 1px solid rgba(black,0.2); } }

    &__link {
        //@extend .flex-center
        background: $orange;
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
        border-radius: 4px;
        text-decoration: none;
        padding: 10px 20px;
        @include breakpoint(sm) {
                padding: 8px 12px; }
        color: white;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 500;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards; }

    &__download {
        margin-top: 20px;
        &__links {
            display: flex;
            flex-direction: row;
            margin-top: 10px; } } }

/* The Modal (background) */
.modal {
    display: none /* Hidden by default */;
    position: fixed /* Stay in place */;
    z-index: 1 /* Sit on top */;
    padding-top: 100px /* Location of the box */;
    left: 0;
    top: 0;
    width: 100% /* Full width */;
    height: 100% /* Full height */;
    overflow: auto /* Enable scroll if needed */;
    background-color: rgb(0,0,0) /* Fallback color */;
    background-color: rgba(0,0,0,0.4) /* Black w/ opacity */; }

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 700px;
    @include breakpoint(sm) {
        width: 350px; } }


/* The Close Button */
.close {
    margin-top: -36px;
    margin-right: -36px;
    float: right;
    display: block;
    height: 36px;
    width: 36px;
    line-height: 32px;
    font-weight: 200;

    -moz-border-radius: 20px /* or 50% */;
    border-radius: 20px /* or 50% */;

    background-color: $orange;
    color: white;
    text-align: center;
    font-size: 2em;
    cursor: pointer;
    text-decoration: none;
    @extend .z-depth-2;

    @include transition;
    &:hover {
        @extend .z-depth-4; }
    opacity: 0;
    animation: fadeup $transition2 $transition2 forwards; }

.om-behandler {
    height: 250px;
    background: $light-grey;
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;
    //padding-top: 40px
    //padding-bottom: 40px
    @include breakpoint(sm) {
        flex-direction: column;
        height: 480px; }

    &__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //margin-bottom: 40px
        @include breakpoint(sm) {
            padding-bottom: 210px; } }
    &__tekst {
        margin-bottom: 20px;
        margin-top: 0; }
    &__link {
        background: $orange;
        width: 180px;
        border-radius: 4px;
        text-decoration: none;
        text-align: center;
        padding: 10px 56px;
        @include breakpoint(sm) {
            padding: 8px 50px; }
        color: $light-grey;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 700;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards; }

    &__middle {}

    &__img {
        position: absolute;
        right: 180px;
        bottom: 170px;
        max-height: 300px;

        margin: 0;
        max-width: 100%;
        height: auto;
        min-width: 200px;
        @include breakpoint(md) {
            width: 250px; }
        @include breakpoint(sm) {
            bottom: 380px;
            width: 150px;
            left: 10px; }
        @include breakpoint(xs) {
            display: none; }

        &:hover {
            filter: grayscale(100%); } }

    &__right {
        position: absolute;
        bottom: 170px;
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        padding-bottom: 20px;
        right: 20px;
        @include breakpoint(sm) {
            left: 180px;
            bottom: 390px; } }
    &__name {
        font-size: 15px; }
    &__title {
        display: block;
        font-size: 13px; }
    &__signature {
        filter: invert(100%);
        margin-top: 10px;
        width: 180px; } }
