.home .header {
    background-color: transparent;
    &__active {
        background: white !important;
        .header__logo {
            svg {
                margin-top: 0;
                color: black;
                width: auto; } }
        .nav__desktop__menu {
            a {
                color: black;
                &:hover {
                    padding-bottom: 8px;
                    border-bottom: 2px solid black; } } }

        .nav__toggle {
            span {
                border-bottom: 2px solid $primary !important; } } }


    &__logo {
        svg {
            color: white;
            height: auto;
            margin-top: 40px;
            width: 260px;
            @include breakpoint(md) {
                margin-top: 0;
                height: 40px;
                width: auto; }
            @include breakpoint(sm) {
                margin-top: 0;
                height: 35px;
                width: auto; } } }
    .nav__toggle {
        span {
            border-bottom: 2px solid white; } } }

.header {
    position: fixed;
    width: 100%;
    z-index: 200;
    background: white;
    height: 80px;
    transition: all 0.5s ease-in-out;
    @include breakpoint(md) {
        height: 80px; }
    @include breakpoint(sm) {
        height: 60px; }
    .row {
        height: 100%; }

    &__logo {
        height: 100%;
        a {
            @extend .flex-vertical;
            @include breakpoint(sm) {
                height: 60px; } }
        svg {
            color: black;
            @include breakpoint(md) {
                height: auto;
                width: 70px; }
            @include breakpoint(sm) {
                height: 40px; } } }
    svg {
        width: auto;
        @include breakpoint(md) {
            height: 40px; }
        @include breakpoint(sm) {
            height: 35px; } } }

.home .nav {

    &__desktop {
        @include breakpoint(md) {
            display: none; }
        &__menu {
            text-align: center;
            display: flex;
            justify-content: space-around;
            a {
                color: white;
                &:hover {
                    padding-bottom: 8px;
                    border-bottom: 2px solid white; } } } } }


.nav {

    &__desktop {
        @include breakpoint(md) {
            display: none; }
        &__menu {
            text-align: center;
            display: flex;
            justify-content: space-around;
            a {
                color: black;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;
                @include breakpoint(lg) {
                    font-size: 15px; } }
            li {
                text-align: center;
                //text-transform: uppercase

                // &:first-child
                //     text-align: left
                &:last-child {
 }                    // text-align: right
                &.current-menu-item {
                    a {
                        //color: $lightblue
                        //font-weight: bold
                        padding-bottom: 8px;
                        border-bottom: 2px solid black;
                        pointer-events: none; } }

                &.current-page-ancestor {
                    a {
                        //color: $lightblue
                        //font-weight: bold
                        padding-bottom: 8px;
                        border-bottom: 2px solid black;
                        pointer-events: none; } }



                .page-template-template_behandling_parent, .page-template-template_behandling &.menu-item-135 {
                    a {
                        padding-bottom: 8px;
                        border-bottom: 2px solid black; } }

                .parent-pageid-735 &.menu-item-737 {
                    a {
                        padding-bottom: 8px;
                        border-bottom: 2px solid black; } }

                .parent-pageid-89 &.menu-item-133 {
                    a {
                        padding-bottom: 8px;
                        border-bottom: 2px solid black; } }

                .parent-pageid-1739 &.menu-item-133 {
                    a {
                        padding-bottom: 8px;
                        border-bottom: 2px solid black; } }

                .parent-pageid-332 &.menu-item-133 {
                    a {
                        padding-bottom: 8px;
                        border-bottom: 2px solid black; } }

                .page-template-template_foer-efter_parent, .page-template-template_foer-efter &.menu-item-877 {
                    a {
                        padding-bottom: 8px;
                        border-bottom: 2px solid black; } } }


            a {
                // color: white
                // @extend h2
                &:hover {
                    //color: $lightblue
                    //font-weight: bold
                    padding-bottom: 8px;
                    border-bottom: 2px solid black; } } } }

    &__toggle {
        display: none;
        width: 80px;
        height: 80px;
        z-index: 100;
        position: absolute;
        top: 0;
        right: 0;
        appearance: none;
        background: none;
        @include transition;
        // border: 1px solid black
        @include breakpoint(md) {
            display: block;
            height: 80px;
            width: 80px; }
        @include breakpoint(sm) {
            height: 60px;
            width: 60px; }
        span {
            width: 50%;
            left: 25%;
            border-bottom: 2px solid $primary;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            display: block;
            position: absolute;
            transform-origin: 50%;
            @include transition;
            &:first-child {
                top: 35%;
                .nav-is-open & {
                    top: 50%;
                    transform: rotate(45deg); }
                .nav-submenu-is-open & {
                    transform: rotate3d(0,0,1,-45deg);
                    width: 20%;
                    top: 50% !important;
                    transform-origin: 0 0;
                    left: 30%; } }
            &:nth-child(2) {
                top: 50%;
                .nav-is-open & {
                    opacity: 0; }
                .nav-submenu-is-open & {
                    opacity: 1;
                    width: 40%;
                    border-width: 2px;
                    left: 30%; } }
            &:last-child {
                top: 65%;
                .nav-is-open & {
                    top: 50%;
                    transform: rotate(-45deg); }
                .nav-submenu-is-open & {
                    transform: rotate3d(0,0,1,45deg);
                    width: 20%;
                    top: 51% !important;
                    transform-origin: 0 0;
                    left: 32%; } } } }

    &__mobile {
        display: none;
        @include coverall;
        @extend .flex-center;
        overflow: scroll;
        width: 100vw;
        z-index: 990;
        position: fixed;
        transform-origin: top right;
        transform-origin: top center;
        opacity: 0;
        border: 1px solid white;
        pointer-events: none;
        border-width: 10px;
        border-top: none;
        transition: all 1s $easing;
        transform: scale(1.1);
        @include breakpoint(md) {
            display: flex;
            height: calc( 100% - 80px);
            top: 80px; }
        @include breakpoint(sm) {
            height: calc( 100% - 60px);
            top: 60px; }
        .nav-is-open & {
            pointer-events: all;
            opacity: 1;
            transform: scale(1); }
        .menu-mobile-container {
            height: 100%; }
        &__inner {
            text-align: center;
            position: relative;
            width: 100%;
            height: 100%;
            background-color: $third;
            // margin-top: -80px;
            #menu-mobile {
                height: 100%; } }
        #menu-cvu_mobile {
            position: relative;
            > li a {} }

        a {
            font-size: 18px;
            text-transform: uppercase;
            color: black;
            line-height: 20px;
            padding: 15px 0;
            transition: text-shadow 0.25s;
            display: block;
            &:hover {
                color: $orange; } }

        &__back {
            display: none !important;
            color: rgba(white, 0.6) !important;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            width: 50px;
            height: 50px;
            width: 40px;
            top: 10px;
            right: 0;
            z-index: 100;
            margin: 0 !important;
            // @extend .flex-center
            // display: flex !important
            svg path {
                fill: white; }
            &.show {
                opacity: 1;
                visibility: visible; } }
        ul.menu {
            display: flex; }
        ul.sub-menu,ul.menu {
            flex-flow: column;
            justify-content: center;
            padding: 20px;
            margin: 0;
            position: absolute;
            overflow: scroll;
            top: 0px;
            left: 0;
            width: 100%;
            min-height: 100%; }

        .sub-menu {
            // visibility: hidden;
            display: none;
            pointer-events: none;
            opacity: 0;

            a {
 } }                // font-weight: 700

        .is-open {
            #nav-toggle {
                background: $green;
                span {
                    top: 50% !important; }

                span:first-child {
                    // transform: rotate3d(0, 0, 1, 315deg)
                    transform: rotateZ(315deg); }
                span:nth-child(2) {
                    opacity: 0; }
                span:last-child {
                    // transform: rotate3d(0, 0, 1, 225deg)
                    transform: rotateZ(225deg); } }

            .mobilenav {
                visibility: visible;
                transform: scale3d(1,1,1); } } } }


