.landscape-banner {
    padding-top: 80px;
    height: 60vh;
    width: 100%;
    margin: 60px 0;
    position: relative;
    @include breakpoint(sm) {
        padding-top: 60px; }
    &--hero {
        height: 500px;
        margin: 0;
        @include breakpoint(sm) {
            height: 350px; } }
    &--personlig-side {
        height: 200px;
        background-color: $light-grey; }
    &__img {
        @include coverall;
 } }        //position: absolute


.iframe-container {
    overflow: hidden;
    @include breakpoint(md) {
        padding-top: 320%; }
    @include breakpoint(dt) {
        padding-top: 110%; }
    @include breakpoint(lg) {
        padding-top: 74%; }
    @include breakpoint(xl) {
        padding-top: 54%; }
    position: relative; }

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.booking-container {
    padding-top: 40px;
    padding-bottom: 80px;
 }    //max-width: 1200px

.metodika {}



.testimonials {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    &:after {
        content: '';
        position: absolute;
        top: 70%;
        left: 0;
        height: 50%;
        width: 100%;
        //background: $light-grey
        z-index: -1; }
    &__inner {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24);
        animation: fadeup $transition 0.5s $easing forwards;
        // transform: translateY(-100px)
        opacity: 0;
        margin-top: 40px;
        transform: translateY(100px);
        background: white;
        position: relative;
        @include breakpoint(lg) {
            padding-top: 30px !important;
            padding: 100px 120px;
            margin-bottom: 60px; }

        @include breakpoint(dt) {
            padding-top: 30px !important;
            padding: 80px 40px;
            margin-top: 40px;
            margin-bottom: 120px; }
        @include breakpoint(md) {
            padding-top: 30px !important;
            padding: 80px 40px;
            margin-top: 40px;
            margin-bottom: 120px; }
        @include breakpoint(sm) {
            padding-top: 50px !important;
            padding: 50px 40px;
            margin-top: 40px;
            margin-bottom: 80px;

 }            //padding: 40px 20px
        &:after, &:before {
            content: '';
            position: absolute;
            top: 100px;
            display: block;
            z-index: 10;
            height: 250px;
            width: 70px;
            opacity: 0.67;
            //background-image: linear-gradient(80deg, #FFFFFF 46%, #D8D8D8 100%)
            @include breakpoint(md) {
                top: 80px;
                width: 40px; }
            @include breakpoint(sm) {
 } }                //display: none
        &:before {
            left: -70px;
            @include breakpoint(md) {
                left: -40px; } }
        &:after {
            right: -70px;
            transform: rotateY(180deg);
            @include breakpoint(md) {
                right: -40px; } } }
    &__title {
        margin-top: 0;
        margin-bottom: 20px;
        color: $orange; }
    &__description p {
        font-weight: 600;
        max-width: 1000px;
        padding: 0 50px;
        color: $link-grey;
        text-align: center;
        margin: 0 auto;
        // max-width: 1000px
        //margin-bottom: 20px
        @include breakpoint(sm) {
            padding: 0;
 } }            //margin-bottom: 20px
    &__slider {
        position: relative;
        padding: 0 40px;
        padding-top: 40px;
        @include breakpoint(sm) {
            padding: 0; } }
    .prev, .next {
        border: 1px solid $orange;
        color: $orange;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        @include breakpoint(sm) {
            height: 35px;
            width: 35px;
            border: none;
            box-shadow: none;
            &:hover {
               box-shadow: none; } }
        // margin: 0
        @extend .flex-center;
        @extend .z-depth-2;
        &:hover {
            @extend .z-depth-3; }

        svg {
            margin-right: 3px; } }
    .prev {
        left: -6px;
        @include breakpoint(md) {
            left: -6px; }
        @include breakpoint(sm) {
            left: -2px; } }
    .next {
        right: -6px;
        transform: rotateY(180deg);
        @include breakpoint(md) {
            right: -6px; }
        @include breakpoint(sm) {
            right: -2px; } }
    .js_slides {
        width: 100%;
        padding: 0;
        @include breakpoint(md) {
 } }            // width: 80%
    .js_slide {
 }        // margin: 20px
    &__li {
        //width: calc(100% / 3)
        //min-height: 300px
        padding: 80px 40px;
        padding-bottom: 0;
        min-height: 0;
        @include breakpoint(dt) {
            width: calc(100% / 2);
 }            //padding: 60px 40px
        @include breakpoint(xl) {
            width: calc(100% / 4);
 }            //padding: 60px 40px
        @include breakpoint(lg) {
            width: calc(100% / 3);
 }            //padding: 60px 40px
        @include breakpoint(sm) {
            width: calc(100% / 1);
            padding: 50px 0px;
            margin: 0px 20px; }
        @include breakpoint(md) {
            width: calc(100% / 1);
 } }            //padding: 60px 40px
    &__li__inner {
        background: $light-grey;
        height: 100%;
        padding: 70px 20px;
        padding-bottom: 40px;
        @include breakpoint(md) {
            padding: 70px 20px;
            padding-bottom: 40px; }
        @include breakpoint(sm) {
            padding: 70px 30px;
            padding-bottom: 40px; } }

    &__img {
        text-align: center; }

    &__link {
        background: $orange;
        // box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24)
        border-radius: 4px;
        text-decoration: none;
        padding: 10px 20px;
        @include breakpoint(sm) {
                padding: 8px 12px; }
        color: white;
        font-family: Raleway;
        font-size: 16px;
        @extend .z-depth-2;
        font-weight: 500;
        @include transition;
        &:hover {
            @extend .z-depth-4; }
        opacity: 0;
        animation: fadeup $transition2 $transition2 forwards;
        margin-top: 80px; } }

.card {
    &__inner {
        position: relative;
        background: $light-grey;
        height: 100%;
        &__foer-efter {
            padding: 20px;
            position: relative;
            background: $light-grey;
            height: 100%; } }
    figure {
        position: absolute;
        top: 0px;
        left: calc(50% - 74px);
        border: 6px solid white;
        border-radius: 50%;
        background: $light-grey;
        color: $primary;
        height: 150px;
        width: 150px;
        // @extend .flex-center
        margin: 0 auto;
        overflow: hidden;
        @include breakpoint(sm) {
            top: 0px;
            left: calc( 50% - 59px);
            height: 120px;
            width: 120px;
            border: 4px solid white; }
        // svg
        //   position: absolute
        //   height: 30px
        //   width: 30px
        //   top: calc(50% - 15px)
 } }        //   left: calc(50% - 15px)

.personlig-side {
    &__pic {
        padding-bottom: 20px; }
    &__name {
        h1 {
            margin: 0; } }
    &__title {
        h5 {
            margin-top: 4px; } }
    &__text {}

    &__link {
        color: $orange;
        margin-right: 40px;
        svg {
            margin-right: 6px;
            margin-bottom: -2px; } } }

.priser {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__post {
        padding-top: 60px;
        h1 {
            margin-top: 0;
            margin-bottom: 10px; } }

    &__content {
        padding-top: 80px; }

    &__category {}



    &__title {}

    &__subtitle {
        h5 {
            margin-top: 20px;
            margin-bottom: 0px; }
        &__a {
            margin-top: 18px;
            padding-left: 0.75rem; } }

    &__pris {
        text-align: right; }

    &__line {
        display: flex;
        flex-direction: row;
        border-bottom: 1px dotted;
        p {
            margin-top: 10px;
            margin-bottom: 10px; } }

    &__link {
        color: $orange;
        margin-top: 30px;
        margin-bottom: 0px;
        svg {
            margin-right: 6px;
            margin-bottom: -2px; } } }



.priser-card {
    align-items: center;
    //border-bottom: 1px solid $light-grey
    padding-top: 80px;
    padding-bottom: 80px;
    //padding-bottom: 80px
    background: white;
    @include breakpoint(sm) {
        padding-top: 50px; }
    h2 {
        margin-top: 0;
        margin-bottom: 10px;
        color: $orange; }
    p {
        margin-bottom: 26px;
        //margin-right: 40px
        max-width: 600px;
 }        //text-align: center
    h3 {
        margin-top: 0;
        margin-bottom: 30px;
        font-weight: 500;
        color: white; }
    &__inner {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24);
        animation: fadeup $transition 0.5s $easing forwards;
        // transform: translateY(-100px)
        opacity: 0;
        transform: translateY(100px);
        background: white;
        position: relative;
        padding-top: 20px !important;
        padding: 40px;
        margin-bottom: 50px; } }

